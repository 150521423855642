document.addEventListener('turbolinks:load', function () {
  document.querySelectorAll('input[name="content_form[is_pop]"]').forEach((input) => {
    input.addEventListener('click', handleTemplateClick);
  });
});

function setDefaultRadioButton(area, radioGroupName) {
  const radioButtons = area.querySelectorAll(`[name="content_form[${radioGroupName}]"]`);
  radioButtons[0].checked = true;
}

function handleTemplateClick(e) {
  const clickedType = e.target.id;
  const areaPopUp = document.getElementById("area_pop_up");
  const areaBuiltIn = document.getElementById("area_built_in");

  if (clickedType === "content_form_is_pop_true") {
    setDefaultRadioButton(areaPopUp, 'm_template_id');
  } else {
    setDefaultRadioButton(areaBuiltIn, 'm_template_id');
  }
}
