document.addEventListener('turbolinks:load', function () {
  // ボタン追加ボタンがクリックされたとき
  document.querySelectorAll('.add-btn-button').forEach(function (element) {
    element.addEventListener('click', click_designe_button);
  });

  function click_designe_button(e) {
    const buttonsContainer = e.target.closest('.buttons_container');
    const buttonAddField = buttonsContainer.querySelector('.button-add-field');
    const buttonFields = buttonAddField.querySelectorAll('.button_field');
    let buttonField;

    if (buttonFields.length == 0) {
      buttonField = document.createElement('div');
      buttonField.classList.add('button_field');

      buttonField.innerHTML = `
        <div class="button-input-container">
          <div class="button-input">
            <div class="input-text"><input placeholder="ボタン名を入力してください。" name="content_form[content_buttons_attributes][0][button_name]" value="" class="field_name" type="text"></div>
            <div class="input-text"><input placeholder="https://" name="content_form[content_buttons_attributes][0][button_url]" value="" class="field_url" type="text"></div>
            <input name="content_form[content_buttons_attributes][0][id]" value="" class="field_id" type="hidden">
          </div>
          <div class="field-order">
            <label for="content_form[content_buttons_attributes][0][position]" class="field-order__label">表示順</label>
            <div class="field-order__input">
              <input name="content_form[content_buttons_attributes][0][position]" value="" class="field_position" type="number" step="1" pattern="[0-9]*" value="1">
            </div>
          </div>
          <div class="field-remove">
            <button type="button" class="remove-button btn-remove">
              <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 448 512">
                <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
              </svg>
              削除
            </button>
          </div>
        </div>
        `;
    } else {
      buttonField = buttonFields[0].cloneNode(true);
      buttonField.querySelector('.field_name').value = '';
      buttonField.querySelector('.field_url').value = '';
      buttonField.querySelector('.field_id').value = '';
      buttonField.querySelector('.field_position').value = '';
      if (buttonField.querySelector('.field_destroy') != null) {
        buttonField.querySelector('.field_destroy').value = false;
      }
      buttonField.closest('.button_field').style.display = 'block';
    }

    buttonField
      .querySelector('.remove-button')
      .addEventListener('click', ClickRemoveButton);
    buttonAddField.appendChild(buttonField);
    reset_button_id();
  }

  // 削除ボタン
  document.querySelectorAll('.remove-button').forEach((element) => {
    element.addEventListener('click', ClickRemoveButton);
  });

  function ClickRemoveButton(element) {
    let area = element.target.closest('.button_field');
    if (area.querySelector('.field_id').value != '') {
      element.target.closest('.button_field').style.display = 'none';
      let destroy = area.querySelector('.field_destroy');
      if (destroy != null) {
        destroy.value = true;
      }
    } else {
      // 新規追加分
      area.remove();
    }
    reset_button_id();
  }

  function reset_button_id() {
    //コンテナの取得
    const buttonsContainers = document.querySelectorAll('.buttons_container');

    buttonsContainers.forEach(function (element) {
      const buttonsContainer = element;
      const buttonFields = buttonsContainer.querySelectorAll('.button_field');

      buttonFields.forEach((element, index) => {
        element
          .querySelector('.field_name')
          .setAttribute(
            'name',
            `content_form[content_buttons_attributes][${index}][button_name]`
          );
        element
          .querySelector('.field_url')
          .setAttribute(
            'name',
            `content_form[content_buttons_attributes][${index}][button_url]`
          );
        element
          .querySelector('.field_id')
          .setAttribute(
            'name',
            `content_form[content_buttons_attributes][${index}][id]`
          );
        element
          .querySelector('.field_position')
          .setAttribute(
            'name',
            `content_form[content_buttons_attributes][${index}][position]`
          );
        if (element.querySelector('.field_destroy') != null) {
          element
            .querySelector('.field_destroy')
            .setAttribute(
              'name',
              `content_form[content_buttons_attributes][${index}][_destroy]`
            );
        }
      });
    });
  }
  reset_button_id();
});
