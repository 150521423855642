import Sortable from 'sortablejs';

document.addEventListener('turbolinks:load', function() {
  //URLsearchParamsを使って、URLに?以降の値があるかどうかを判定する
  const params = new URLSearchParams(window.location.search);
  let isEmptyQuery = true;

  for(const [key, value] of params) {
    //queryとbuttonの値が空でない場合は、検索機能を使っていると判断する
    if ((key === 'query' || key === 'button') && value !== '') {
      isEmptyQuery = false;
      break;
    }
  }

  // 検索機能を使っている場合、(URLに?以降の値がある場合は実行しない)
  if (!isEmptyQuery) {
    return;
  }

  document.querySelectorAll('.drag_and_drop').forEach(function (element) {
    const tableBody = element.querySelector('tbody');
    Sortable.create(tableBody, {
      animation: 150,
      onUpdate: function (event) {
        //csrfトークンを取得
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");

        //tr要素にdata-item-id属性を付与しているので、それを取得して配列にする
        let itemIdArray = Array.from(document.querySelectorAll('tr[data-item-id]')).map(tr => tr.getAttribute('data-item-id'));

        //oldIndexはitemが移動する前のインデックス番号、newIndexは移動後のインデックス番号
        let minNo = Math.min(event.oldIndex, event.newIndex);
        let maxNo = Math.max(event.oldIndex, event.newIndex);

        //配列の中身をminNoからmaxNoまでに絞り込む
        itemIdArray = itemIdArray.slice(minNo, maxNo + 1); //sliceの仕様上、maxNoは含まれないので、+1する

        const data = {
          itemIdArray: itemIdArray,
          minNo: minNo + 1, //indexは0から始まるので、railsの表示順に合わせるため+1する
        };

        sendSortToServer(data, csrfToken);
      }
    });

    async function sendSortToServer(data, csrfToken) {
      await fetch("/update_order", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token" : csrfToken
        },
      })
      .then((response) => response.json())
      .then((responseData) => {
        console.log("success:", responseData);
        window.location.reload();
      })
      .catch((error) => {
        console.log("エラー", error)
      })
    }
  });
});
