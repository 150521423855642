document.addEventListener('turbolinks:load', function () {
  document.querySelectorAll('.content_image-select__btn').forEach((elm) => {
    elm.addEventListener('click', inputClick);
  });
  function inputClick(e) {
    const area = e.target.closest('.image-field');
    const fileInput = area.querySelector('.field_file');
    fileInput.click();
  }

  // ファイルを選択した時
  document.querySelectorAll('.field_file').forEach(function (element) {
    element.addEventListener('change', change_image);
  });
  function change_image(e) {
    const urlsContainer = e.target.closest('.image-preview-container');
    const image = urlsContainer.querySelector('.image-preview');
    const [file] = e.target.files;

    if (file) {
      image.setAttribute('src', URL.createObjectURL(file));
      image.style.display = 'block';
    } else {
      image.style.display = 'none';
    }
  }

  // 画像追加ボタンがクリックされたとき
  document.querySelectorAll('.add-image-button').forEach(function (element) {
    element.addEventListener('click', click_designe_image);
  });

  function click_designe_image(e) {
    const urlsContainer = e.target.closest('.images_container');
    const imageAddField = urlsContainer.querySelector('.image-add-field');
    const urlFields = imageAddField.querySelectorAll('.image-field');
    let urlField;

    if (urlFields.length == 0) {
      urlField = document.createElement('div');
      urlField.classList.add('image-field');

      urlField.innerHTML = `
        <div class="image-preview-container">
          <img src="" alt="選択した画像" class="image-preview" style="display: none;">
          <div class="image-select">
            <input name="content_form[content_images_attributes][0][id]" value="" class="field_id" type="hidden">
            <input name="content_form[content_images_attributes][0][image]" value="" class="field_file" type="file" accept=".png,.jpg,.webp">
            <button type="button" class="content_image-select__btn image-select__btn">ファイルを選択</button>
          </div>
          <div class="field-order">
            <label for="content_form[content_images_attributes][0][position]" class="field-order__label">表示順</label>
            <div class="field-order__input">
              <input name="content_form[content_images_attributes][0][position]" value="" class="field_position" type="number" step="1" pattern="[0-9]*" value="1">
            </div>
          </div>
          <div class="field-remove">
            <button type="button" class="remove-image btn-remove">
              <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 448 512">
                <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
              </svg>
              削除
            </button>
          </div>
        </div>
        `;
    } else {
      urlField = urlFields[0].cloneNode(true);
      urlField.querySelector('.image-preview').src = '';
      urlField.querySelector('.image-preview').style.display = 'none';
      urlField.querySelector('.field_file').value = '';
      urlField.querySelector('.field_id').value = '';
      urlField.querySelector('.field_position').value = '';
      if (urlField.querySelector('.field_destroy') != null) {
        urlField.querySelector('.field_destroy').value = false;
      }
    }
    urlField
      .querySelector('.field_file')
      .addEventListener('change', change_image);
    urlField
      .querySelector('.content_image-select__btn')
      .addEventListener('click', inputClick);
    urlField
      .querySelector('.remove-image')
      .addEventListener('click', ClickRemoveImage);
    imageAddField.appendChild(urlField);
    reset_image_id();
  }

  // 削除ボタン
  document.querySelectorAll('.remove-image').forEach((element) => {
    element.addEventListener('click', ClickRemoveImage);
  });

  function ClickRemoveImage(element) {
    let area = element.target.closest('.image-field');
    if (area.querySelector('.field_id').value != '') {
      element.target.closest('.image-field').style.display = 'none';
      let destroy = area.querySelector('.field_destroy');
      if (destroy != null) {
        destroy.value = true;
      }
    } else {
      // 新規追加分
      area.remove();
    }
    reset_image_id();
  }

  function reset_image_id() {
    //コンテナの取得
    const urlsContainers = document.querySelectorAll('.image-add-field');

    urlsContainers.forEach(function (element) {
      const urlsContainer = element;
      const urlFields = urlsContainer.querySelectorAll('.image-field');

      urlFields.forEach((element, index) => {
        let fieldFile = element.querySelector('.field_file');
        let fieldId = element.querySelector('.field_id');
        let fieldPosition = element.querySelector('.field_position');
        let fieldDestroy = element.querySelector('.field_destroy');

        if (fieldFile) {
          fieldFile.setAttribute(
            'name',
            `content_form[content_images_attributes][${index}][image]`
          );
        }
        if (fieldId) {
          fieldId.setAttribute(
            'name',
            `content_form[content_images_attributes][${index}][id]`
          );
        }
        if (fieldPosition) {
          fieldPosition.setAttribute(
            'name',
            `content_form[content_images_attributes][${index}][position]`
          );
        }
        if (fieldDestroy) {
          fieldDestroy.setAttribute(
            'name',
            `content_form[content_images_attributes][${index}][_destroy]`
          );
        }
      });
    });
  }
  reset_image_id();
});
