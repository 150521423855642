document.addEventListener('turbolinks:load', function () {
  // 配信ページ追加ボタンがクリックされたとき
  document.querySelectorAll('.add-url-button2').forEach(function (element) {
    element.addEventListener('click', click_designe_url1);
  });

  function click_designe_url1(e) {
    const urlsContainer = e.target.closest('.urls_container1');
    const urlFields = urlsContainer.querySelectorAll('.url-field');
    let urlField;

    if (urlFields.length == 0) {
      urlField = document.createElement('div');
      urlField.classList.add('url-field');

      urlField.innerHTML = `
        <div class="url-field-contents">
          <div class="input-text">
            <input placeholder="https://" name="content_form[content_delivery_pages_attributes][0][url]" value="" class="field_name" type="text">
          </div>
          <input name="content_form[content_delivery_pages_attributes][0][id]" value="" class="field_id" type="hidden">
          <div class="field-remove">
            <button type="button" class="remove-field1 btn-remove">
              <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 448 512">
                <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
              </svg>
              削除
            </button>
          </div>
        </div>
        `;
    } else {
      urlField = urlFields[0].cloneNode(true);
      urlField.querySelector('.field_name').value = '';
      urlField.querySelector('.field_id').value = '';
      if (urlField.querySelector('.field_destroy') != null) {
        urlField.querySelector('.field_destroy').value = false;
      }
      urlField.closest('.url-field').style.display = 'block';
    }

    urlField
      .querySelector('.remove-field1')
      .addEventListener('click', ClickRemove1);
    urlsContainer.appendChild(urlField);
    reset_m_node_id1();
  }

  // 削除ボタン
  document.querySelectorAll('.remove-field1').forEach((element) => {
    element.addEventListener('click', ClickRemove1);
  });

  function ClickRemove1(element) {
    let area = element.target.closest('.url-field');
    if (area.querySelector('.field_id').value != '') {
      element.target.closest('.url-field').style.display = 'none';
      let destroy = area.querySelector('.field_destroy');
      if (destroy != null) {
        destroy.value = true;
      }
    } else {
      // 新規追加分
      area.remove();
    }
    reset_m_node_id1();
  }

  function reset_m_node_id1() {
    // コンテナの取得
    const urlsContainers = document.querySelectorAll('.urls_container1');

    urlsContainers.forEach(function (element) {
      const urlsContainer = element;
      const urlFields = urlsContainer.querySelectorAll('.url-field');

      urlFields.forEach((element, index) => {
        const field_name = element.querySelector('.field_name');
        const field_id = element.querySelector('.field_id');

        if (field_name && field_id) {
          field_name.setAttribute(
            'name',
            `content_form[content_delivery_pages_attributes][${index}][url]`
          );
          field_id.setAttribute(
            'name',
            `content_form[content_delivery_pages_attributes][${index}][id]`
          );

          const field_destroy = element.querySelector('.field_destroy');
          if (field_destroy != null) {
            field_destroy.setAttribute(
              'name',
              `content_form[content_delivery_pages_attributes][${index}][_destroy]`
            );
          }
        }
      });
    });
  }
  reset_m_node_id1();
});
