document.addEventListener('turbolinks:load', function () {
  // 閲覧履歴追加ボタンがクリックされたとき
  document.querySelectorAll('.add-url-button').forEach(function (element) {
    element.addEventListener('click', click_designe_url);
  });

  function click_designe_url(e) {
    const urlsContainer = e.target.closest('.urls_container');
    const urlFields = urlsContainer.querySelectorAll('.url-field');
    let urlField;

    if (urlFields.length == 0) {
      urlField = document.createElement('div');
      urlField.classList.add('url-field');

      urlField.innerHTML = `
        <div class="url-field-contents">
          <div class="input-text">
            <input placeholder="https://" name="content_form[${urlsContainer.id}][0][name]" value="" class="field_name" type="text" id="content_${urlsContainer.id}_name">
          </div>
          <input name="content_form[${urlsContainer.id}][0][m_node_type_id]" value="" class="field_m_node_type_id" type="hidden" id="content_${urlsContainer.id}_m_node_type_id">
          <input name="content_form[${urlsContainer.id}][0][content_node_id]" value="" class="field_content_node_id" type="hidden" id="content_${urlsContainer.id}_content_node_id">
          <div class="field-remove">
            <button type="button" class="remove-field btn-remove">
              <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 448 512">
                <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
              </svg>
              削除
            </button>
          </div>
        </div>
        `;
    } else {
      urlField = urlFields[0].cloneNode(true);
      urlField.querySelector('.field_name').value = '';
      urlField.querySelector('.field_m_node_type_id').value = '';
      urlField.querySelector('.field_content_node_id').value = '';
      urlField.closest('.url-field').style.display = 'block';
    }

    urlField
      .querySelector('.remove-field')
      .addEventListener('click', ClickRemove);
    urlsContainer.appendChild(urlField);
    reset_m_node_id();
  }

  // 削除ボタン
  document.querySelectorAll('.remove-field').forEach((element) => {
    element.addEventListener('click', ClickRemove);
  });

  function ClickRemove(element) {
    element.target.closest('.url-field').style.display = 'none';
    // 削除ボタンが押されたフォームのURLのバリューを空にする
    element.target.closest('.url-field').querySelector('.field_name').value =
      '';
    reset_m_node_id();
  }

  function reset_m_node_id() {
    //コンテナの取得
    const urlsContainers = document.querySelectorAll('.urls_container');

    urlsContainers.forEach(function (element) {
      const urlsContainer = element;
      const urlFields = urlsContainer.querySelectorAll('.url-field');

      urlFields.forEach((element, index) => {
        const field_name = element.querySelector('.field_name');
        const field_m_node_type_id = element.querySelector(
          '.field_m_node_type_id'
        );
        const field_content_node_id = element.querySelector(
          '.field_content_node_id'
        );

        if (field_name && field_m_node_type_id && field_content_node_id) {
          field_name.setAttribute(
            'name',
            `content_form[${urlsContainer.id}][${index}][name]`
          );
          field_m_node_type_id.setAttribute(
            'name',
            `content_form[${urlsContainer.id}][${index}][m_node_type_id]`
          );
          field_content_node_id.setAttribute(
            'name',
            `content_form[${urlsContainer.id}][${index}][content_node_id]`
          );
        }
      });
    });
  }
  reset_m_node_id();
});
