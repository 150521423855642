document.addEventListener('turbolinks:load', function () {
  // ラジオボタンにイベントリスナーを追加 ウィジウィグ
  document
    .querySelectorAll('#designe_radio_screen input')
    .forEach((element) => {
      element.addEventListener('click', click_screen_designe_radio);
    });

  function click_screen_designe_radio(e) {
    toggleDisplayScreen(e.target);
  }

  function toggleDisplayScreen(target) {
    if (target.dataset.type == 'is_wysiwyg') {
      document.getElementById('area_wysiwyg').style.display = '';
      document.getElementById('area_gamen').style.display = 'none';
    } else {
      document.getElementById('area_wysiwyg').style.display = 'none';
      document.getElementById('area_gamen').style.display = '';
    }
  }

  // ページロード時のラジオボタンの状態をチェックし、適切なエリアを表示 ウィジウィグ
  document
    .querySelectorAll('#designe_radio_screen input')
    .forEach((element) => {
      if (element.checked) {
        toggleDisplayScreen(element);
      }
    });

  // ラジオボタンにイベントリスナーを追加 ポップ
  document.querySelectorAll('#designe_radio_pop input').forEach((element) => {
    element.addEventListener('click', click_pop_designe_radio);
  });

  function click_pop_designe_radio(e) {
    toggleDisplayPop(e.target);
  }

  function toggleDisplayPop(target) {
    if (target.dataset.type == 'is_built_in') {
      document.getElementById('area_built_in').style.display = '';
      document.getElementById('area_pop_up').style.display = 'none';
      document.getElementById('area_built_in_built').style.display = '';
      document.getElementById('area_pop_page').style.display = 'none';
      document.getElementById('area_pop_trigger').style.display = 'none';
    } else {
      document.getElementById('area_built_in').style.display = 'none';
      document.getElementById('area_pop_up').style.display = '';
      document.getElementById('area_built_in_built').style.display = 'none';
      document.getElementById('area_pop_page').style.display = '';
      document.getElementById('area_pop_trigger').style.display = '';
    }
  }

  // ページロード時のラジオボタンの状態をチェックし、適切なエリアを表示 ポップ
  document.querySelectorAll('#designe_radio_pop input').forEach((element) => {
    if (element.checked) {
      toggleDisplayPop(element);
    }
  });
});
