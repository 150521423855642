document.addEventListener("turbolinks:load", function () {
  // プレビューボタンを押した時の処理
  document.querySelectorAll("#button-preview").forEach((element) => {
    element.addEventListener("click", function (element) {
      document.getElementById("div-preview").innerHTML = "";
      isPreviewButtonClicked(element);
    });
  });

  // 編集ページまたは確認ページのプレビューボタンを判別し、適したメソッドを呼び出す
  function isPreviewButtonClicked(element) {
    const isEditMode = element.target.closest('.edit-mode') !== null;
    if (isEditMode) {
      clickButtonPreviewEdit();
    } else {
      clickButtonPreviewShow();
    }
  };

  // プレビューボタンのクリック
  // 画面編集の場合はプレビュー生成のためデータ送信する,ウィジウィグの場合はプレビューを開く
  function clickButtonPreviewEdit() {
    const editType = document.querySelector(
      'input[name="content_form[is_edit_on_screen]"]:checked'
    ).dataset.type;
    if (editType == "is_gamen") {
      EditOnScreen();
    } else {
      EditOnWysiwyg();
    };
  };

  function clickButtonPreviewShow() {
    // コンテンツidを取得
    const contentId = window.location.href.match(/\/(\d+)$/)[1];

    // HTMLのメタタグからCSRFトークンを取得
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    const data = {
      content_id: contentId
    }

    // データを送信
    sendDataToServer("/preview_show", data, csrfToken)
  }

  // 画面編集
  function EditOnScreen() {
    const areaPopUp = document.getElementById("area_pop_up");
    const areaBuiltIn = document.getElementById("area_built_in");


    let checkedTemplateInput;
    // area_pop_up、またはarea_built_inがviewに表示されている場合、チェックが入っている要素を取得する。
    //必ずどちらか一方は表示されているので、チェックされている要素を限定して取得できる。
    if (areaPopUp.style.display !== "none") {
      checkedTemplateInput = areaPopUp.querySelector(
        'input[name="content_form[m_template_id]"]:checked'
      );
    } else if (areaBuiltIn.style.display !== "none") {
      checkedTemplateInput = areaBuiltIn.querySelector(
        'input[name="content_form[m_template_id]"]:checked'
      );
    }

    //取得した要素がnullでない場合、チェックが入っている要素のvalueを取得する。
    const checkedTemplate = checkedTemplateInput
      ? checkedTemplateInput.value
      : null;

    // テンプレートが選択されていない場合はreturn & エラー文表示
    if (checkedTemplate == null) {
      document.getElementById("div-preview").innerHTML =
        '<div style="color: red;">テンプレートを選択してください</div>';
      return;
    }

    // タイトルのvalueを取得
    const titleInput = document.querySelector('input[name="content_form[title]"]');
    const title = titleInput ? titleInput.value : null;

    // 本文のvalueを取得
    const textArea = document.querySelector('textarea[name="content_form[text]');
    const text = textArea ? textArea.value : null;

    // コンテンツ全体の遷移先URLのvalueを取得
    const blockUrlInput = document.querySelector('textarea[name="content_form[block_url]"]');
    const block_url = blockUrlInput ? blockUrlInput.value : null;

    // ボタン名とボタンURLを取得
    const buttonNames = document.querySelectorAll(".button_field .field_name");
    const buttonUrls = document.querySelectorAll(".button_field .field_url");
    const dataButtonUrlSets = [];
    for (let i = 0; i < Math.min(buttonNames.length, buttonUrls.length); i++) {
      const data_button = buttonNames ? buttonNames[i].value : null;
      const data_url = buttonUrls ? buttonUrls[i].value : null;
      dataButtonUrlSets.push({ data_button, data_url });
    };

    // 選択された画像のsrcを取得
    const fileInfos = [];
    document.querySelectorAll(".image-preview-container").forEach((e) => {
      srcValue = e.querySelector("img").getAttribute("src");
      fileInfos.push(srcValue);
    });

    // HTMLのメタタグからCSRFトークンを取得
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    const data = {
      template_id: checkedTemplate,
      data_title: title,
      data_text: text,
      data_block_url: block_url,
      data_button_url_sets: dataButtonUrlSets,
      data_images: fileInfos,
    };

    // データを送信
    sendDataToServer("/preview",data, csrfToken);
  };

  function sendDataToServer(endpoint, data, csrfToken) {
    // fetchメソッドを使用してデータを送信する
    fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
    })
      .then((response) => response.json())
      .then((responseData) => {
        // サーバーから生成されたHTMLが返るので、別タブで開く
        let nwin = window.open("about:blank");
        nwin.document.open();
        nwin.document.write(responseData.preview_html);
        nwin.document.title = "プレビュー";
        nwin.document.close();
      })
      .catch((error) => {
        console.error("エラー:", error);
      });
  };

  // wysiwyg
  function EditOnWysiwyg() {
    // タイトルのvalueを取得
    const wysiwygInput = document.querySelector(
      'textarea[name="content_form[wysiwyg]"]'
    );
    const wysiwyg = wysiwygInput ? wysiwygInput.value : "";

    // テンプレートが選択されていない場合はreturn & エラー文表示
    if (wysiwyg == "") {
      document.getElementById("div-preview").innerHTML =
        '<div style="color: red;">wysiwygを入力してください</div>';
      return;
    };

    let nwin = window.open("about:blank");
    nwin.document.open();
    nwin.document.write("<!DOCTYPE html>");
    nwin.document.write("<html lang='en'>");
    nwin.document.write("<head>");
    nwin.document.write("<meta charset='UTF-8'>");
    nwin.document.write(
      "<meta name='viewport' content='width=device-width, initial-scale=1.0'>"
    );
    nwin.document.write("<title>プレビュー</title>");
    nwin.document.write("</head>");
    nwin.document.write("<body>");
    // wysiwygの内容を挿入
    nwin.document.write(wysiwyg);
    nwin.document.write("</body>");
    nwin.document.write("</html>");
    nwin.document.close();
  };
});
