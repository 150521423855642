document.addEventListener('turbolinks:load', function () {
  document.querySelectorAll('.mail_magazine_image-select__btn').forEach((elm) => {
    elm.addEventListener('click', inputClick);
  });
  function inputClick(e) {
    const area = e.target.closest('.mail_magazine_file_image_field');
    const fileInput = area.querySelector('.mail_magazine_file_field_file');
    fileInput.click();
  }

  document
    .querySelectorAll('.mail_magazine_file_field_file')
    .forEach(function (element) {
      element.addEventListener('change', change_image);
    });

  // ファイルを選択した時、画像のプレビューを表示する
  function change_image(e) {
    const urlsContainer = e.target.closest(
      '.mail_magazine_file_image_preview_container'
    );
    const image = urlsContainer.querySelector('.image_preview');
    const [file] = e.target.files;

    if (file) {
      image.setAttribute('src', URL.createObjectURL(file));
      image.style.display = 'block';
    } else {
      image.style.display = 'none';
    }
  }

  // 画像追加ボタンがクリックされたとき
  document
    .querySelectorAll('.mail_magazine_file_add_image_button')
    .forEach(function (element) {
      element.addEventListener('click', click_design_image);
    });

  function click_design_image(e) {
    // closestは一番近い親要素を取得する,
    const urlsContainer = e.target.closest(
      '.mail_magazine_file_images_container'
    );
    const fileAddField = urlsContainer.querySelector(
      '.mail-magazine-file-add-field'
    );
    const urlFields = fileAddField.querySelectorAll(
      '.mail_magazine_file_image_field'
    );
    let urlField;

    if (urlFields.length == 0) {
      urlField = document.createElement('div');
      urlField.classList.add('mail_magazine_file_image_field');

      urlField.innerHTML = `
        <div class="mail_magazine_file_image_preview_container">
          <img src="" alt="選択した画像" class="image_preview" style="display: none;">
          <div class="image-select">
            <input name="mail_magazine[mail_magazine_files_attributes][0][id]" value="" class="mail_magazine_file_field_id" type="hidden">
            <input name="mail_magazine[mail_magazine_files_attributes][0][file]" value="" class="mail_magazine_file_field_file" type="file" accept=".png,.jpg,.webp">
            <button type="button" class="mail_magazine_image-select__btn image-select__btn">ファイルを選択</button>
          </div>
          <div class="field-order">
            <label for="mail_magazine[mail_magazine_files_attributes][0][position]" class="field-order__label">表示順</label>
            <div class="field-order__input">
              <input name="mail_magazine[mail_magazine_files_attributes][0][position]" value="" class="field_position" type="number" step="1" pattern="[0-9]*" value="1">
            </div>
          </div>
          <div class="field-remove">
            <button type="button" class="mail_magazine_file_remove_image btn-remove">
              <svg xmlns="http://www.w3.org/2000/svg" class="icons" viewBox="0 0 448 512">
                <path fill="currentColor" d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/>
              </svg>
              削除
            </button>
          </div>
        </div>
      `;
    } else {
      urlField = urlFields[0].cloneNode(true);
      urlField.querySelector('.image_preview').src = '';
      urlField.querySelector('.image_preview').style.display = 'none';
      urlField.querySelector('.mail_magazine_file_field_file').value = '';
      urlField.querySelector('.mail_magazine_file_field_id').value = '';
      urlField.querySelector('.field_position').value = '';
      if (urlField.querySelector('.mail_magazine_file_field_destroy') != null) {
        urlField.querySelector(
          '.mail_magazine_file_field_destroy'
        ).value = false;
      }
      urlField.closest('.mail_magazine_file_image_field').style.display =
        'block';
    }
    urlField
      .querySelector('.mail_magazine_file_field_file')
      .addEventListener('change', change_image);
    urlField
      .querySelector('.mail_magazine_image-select__btn')
      .addEventListener('click', inputClick);
    urlField
      .querySelector('.mail_magazine_file_remove_image')
      .addEventListener('click', ClickRemoveImage);
    fileAddField.appendChild(urlField);
    reset_image_id();
  }

  // 削除ボタン
  document
    .querySelectorAll('.mail_magazine_file_remove_image')
    .forEach((element) => {
      element.addEventListener('click', ClickRemoveImage);
    });

  function ClickRemoveImage(element) {
    let area = element.target.closest('.mail_magazine_file_image_field');
    if (area.querySelector('.mail_magazine_file_field_id').value != '') {
      element.target.closest('.mail_magazine_file_image_field').style.display =
        'none';
      let destroy = area.querySelector('.mail_magazine_file_field_destroy');
      if (destroy != null) {
        destroy.value = true;
      }
    } else {
      area.remove();
    }
    reset_image_id();
  }

  function reset_image_id() {
    const urlsContainers = document.querySelectorAll(
      '.mail_magazine_file_images_container'
    );

    urlsContainers.forEach(function (element) {
      const urlsContainer = element;
      const urlFields = urlsContainer.querySelectorAll('.mail_magazine_file_image_field');

      urlFields.forEach((element, index) => {
        let fileField = element.querySelector('.mail_magazine_file_field_file');
        let idField = element.querySelector('.mail_magazine_file_field_id');
        let positionField = element.querySelector('.field_position');
        let destroyField = element.querySelector('.mail_magazine_file_field_destroy');

        if (fileField) {
          fileField.setAttribute(
            'name',
            `mail_magazine[mail_magazine_files_attributes][${index}][file]`
          );
        }

        if (idField) {
          idField.setAttribute(
            'name',
            `mail_magazine[mail_magazine_files_attributes][${index}][id]`
          );
        }

        if (positionField) {
          positionField.setAttribute(
            'name',
            `mail_magazine[mail_magazine_files_attributes][${index}][position]`
          );
        }

        if (destroyField) {
          destroyField.setAttribute(
            'name',
            `mail_magazine[mail_magazine_files_attributes][${index}][_destroy]`
          );
        }
      });
    });
  }
  reset_image_id();
});
